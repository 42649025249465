import React from "react";
import { BiCaretRight } from "react-icons/bi";
import { AiOutlineStop } from "react-icons/ai";

import ReactTooltip from "react-tooltip";

const SingleFeatured = (props) => {
  const featuredTitle = props.featuredTitle;
  const text = props.text;
  const img = props.img;
  const link = props.link;
  const linkText = props.linkText;
  const gitHubLink = props.gitHubLink;

  return (
    <div className="block px-5 md:flex my-8 items-center">
      <div className="w-full md:w-1/2 md:pr-5">
        <img src={img} alt="abc" className="w-auto"></img>
      </div>
      <div className="w-full mt-2 md:w-1/2 ">
        <p className="my-5 bg-blue inline-block px-4 py-2 text-white ">
          <strong>{featuredTitle}:</strong>
        </p>
        <p>{text}</p>
        {gitHubLink ? (
          <>
            <a href={gitHubLink} target="_blank" rel="noreferrer">
              View on GitHub
              <BiCaretRight className="inline" />
            </a>
            <br />
          </>
        ) : (
          ""
        )}

        {linkText != "Classified" ? (
          <a
            href={link}
            className="hover:text-darkblue my-2 inline-block"
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
            <BiCaretRight className="inline" />
          </a>
        ) : (
          <div>
            <p
              className="font-bold text-red-500"
              data-tip="Some projects contain sensitive informaiton and cannot be shared."
            >
              Website is Classified
              <AiOutlineStop className="inline relative bottom-[1px]" />
            </p>
            <ReactTooltip />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleFeatured;
