import React from "react";
const Portfolio = () => {

return (
<div className="w-1/2 w-min mx-auto mt-[60px] h-[60vh] flex flex-col align-center">
    <div className="w-full"><h1 className="text-9xl">404</h1></div>
    <div  className="w-full text-center"><p>Something went wrong. This page does not exist. Please go back and try again.</p></div>

</div>    
)
};

export default Portfolio;


