import React from "react";
import phase0 from "../../assets/web/phase0-website.gif";
import ztresources from "../../assets/web/zerotech-resources.gif";
import defence from "../../assets/web/tsa-defence.gif";
import ztportal from "../../assets/web/zt-portal.gif";
import shipping from "../../assets/web/shipping.gif";
import wordle from "../../assets/web/wordle.gif";
import quiz from "../../assets/web/quiz.gif";
import invoiceGenerator from "../../assets/web/commercial-invoice-generator.gif";
import ztoutdoors from "../../assets/web/zerotechoutdoors.gif";
import skywatcher from "../../assets/web/skywatcher-australia.gif";
import SingleFeatured from "./SingleFeatured";
const FeaturedList = () => {
  return (
    <>
      <SingleFeatured
        featuredTitle="Sky-Watcher Australia"
        text={
          "A NextJS redesign of an online product catalogue. This was built as a personal project as I began experimenting with NextJS. It was exported as a static website and uses local data without a database."
        }
        img={skywatcher}
        link="http://skywatcher.warcherdesign.com/"
        gitHubLink="https://github.com/MrWilliamA/skywatcher-australia-nextjs-json"
        linkText="Check it out"
      />
      <SingleFeatured
        featuredTitle="ZeroTech Resources"
        text={
          "A ReactJS website built to solve one problem. Rather than duplicating resources across multiple websites I decided to create a one-stop-shop for everything ZeroTech. PDF downloads, product information, images etc. "
        }
        img={ztresources}
        link="https://resources.zerotechoptics.com/"
        gitHubLink="https://github.com/MrWilliamA/Zerotech-Resources"
        linkText="Check it out"
      />
      <SingleFeatured
        featuredTitle="TSA Defence"
        text={
          "An ecommerce store built specifically for the Defence and Law Enforcement industries. Built using Avenue CMS (based off Shopify), however it was mostly made with vanilla JavaScript, HTML and CSS."
        }
        img={defence}
        link="https://sales.tasco.net.au/defence"
        linkText="Check it out"
        gitHubLink="https://github.com/MrWilliamA/TSA-Defence"
      />
      <SingleFeatured
        featuredTitle="ZeroTech Dealer portal"
        text={
          "An ecommerce store build (based off Shopify), however it was mostly made with vanilla JavaScript, HTML and CSS. All resources including photos and lightbox are 100% my own."
        }
        img={ztportal}
        link="#"
        linkText="Classified"
      />
      <SingleFeatured
        featuredTitle="ZeroTech Optics Online Store"
        text={
          "An ecommerce store built with WordPress. Featuring a specific branch of Zerotech Optics to target a specific audience. Special attention was paid to marketing and SEO."
        }
        img={ztoutdoors}
        link="https://zerotechoutdoors.com.au/"
        linkText="Go Shopping"
      />
      <SingleFeatured
        featuredTitle="Commercial Invoice Generator"
        text={
          "I was tasked with upgrading the archaic excel invoices our company was sending to international clients. Nothing will ruin your company image like a spreadsheet printed to PDF! I built this SPA so our internal staff could upload their spreadsheets and print to PDF in way that looked more professional. "
        }
        img={invoiceGenerator}
        link="https://invoice.tsaoutdoors.com.au/"
        linkText="See it in action"
        gitHubLink="https://github.com/MrWilliamA/TSA-Invoice-Generator"
      />

      <SingleFeatured
        featuredTitle="Wordle Web App"
        text={
          "For my second assignment I had to make a single page web application. I decided to remake the popular game 'Wordle' in my own style. This was made with HTML, CSS, Vanilla JavaScript and a local JSON server for keeping score."
        }
        img={wordle}
        link="https://mrwilliama.github.io/Wordle-Like-App/"
        linkText="See it in action"
        gitHubLink="https://github.com/MrWilliamA/Wordle-Like-App"
      />
      <SingleFeatured
        featuredTitle="Phase0 Website"
        text={
          "My first assignment at AcedemyXi. This is a remake of my old portfolio website using purely HTML, CSS and a small amount of JavaScript. I was expected to showcase my understanding of basic HTML markup and CSS principals, so naturally I went overboard and made a fully functional HTML website complete with a parallax banner."
        }
        img={phase0}
        link="https://github.com/MrWilliamA/phase0website"
        linkText="Find it on GitHub"
      />
      <SingleFeatured
        featuredTitle="Free Shipping Countdown"
        text={
          "Looking for a way to drive sales on a customer portal, I decided to build a conditional progress bar to gamify the cart process. It works by grabbing the dollar value of the users cart and comparing it to the amount remaining to free shipping. Tricker than it sounds, this free shipping amount is dependent on the users login type."
        }
        img={shipping}
        link="#"
        gitHubLink="https://github.com/MrWilliamA/Amount-until-Free-Shipping-Calculator"
        linkText="Classified"
      />
      <SingleFeatured
        featuredTitle="Pet Name Picker"
        text={
          "My first ever JavaScript project. I made this one for my soon to be wife, never ask a developer for something unless you really mean it. With no formal training and barely any experience, I hacked this together peace by peace. The result...glorious."
        }
        img={quiz}
        link="https://warcherdesign.com/quiz/quiz.html"
        linkText="Play the Quiz"
        gitHubLink="https://github.com/MrWilliamA/PetName-Quiz"
      />
    </>
  );
};

export default FeaturedList;
